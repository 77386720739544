import React, { useContext, useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ProtectedRoute = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      // Show SweetAlert loading spinner
      Swal.fire({
        title: "Loading...",
        text: "Please wait while we prepare your dashboard.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Simulate a delay for loading (if necessary)
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
      Swal.close(); // Close SweetAlert loading spinner
    };

    checkUser();
  }, []);

  if (loading) {
    // While SweetAlert is showing, no need to return a component
    return null;
  }

  if (!user || !user.auth || !user.role) {
    toast.warn("You must be logged in to access this page!", {
      position: "top-right",
      autoClose: 3000,
      style: { marginTop: "70px" },
    });
    return <Navigate to="/lms/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
