
import { Container,Card  } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { RiAddLargeLine } from "react-icons/ri";
import "./Dashboard.css";
import Footer from "../Footer/Footer";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import 'chart.js/auto';
import ArrImage from "../../assets/StaffDashboard/fast-forward.gif"
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Lottie from 'lottie-react'; // Correct import for lottie-react
import animation from '../Animation/Animation.json'; // Correct path to your JSON file
import NoDataFound from "../Animation/NoDataFound.json"
import Swal from 'sweetalert2';
import { API_URL } from "../../global";
import { UserContext } from "../../context/UserContext";
import pdfFile from "../Download/FacultyList.xlsx"
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
ChartJS.register(ArcElement, Tooltip, Legend, Title);

export default function Dashboard() {
  const {
    user,

  } = useContext(UserContext);
const navigate = useNavigate();
const [getBatchData, setGetBatchData] = useState([])
const [showModal, setShowModal] = useState(false); // State to manage modal visibility
const [file, setFile] = useState(null); // State to store selected file
const [isLoading, setIsLoading] = useState(false); // State for loading status

const {id} = useParams()


// Function to handle file change (when user selects an Excel file)
const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  if (selectedFile) {
    // Check file format
    if (!selectedFile.name.match(/\.(xlsx|xls)$/)) {
      Swal.fire({
        title: "Error",
        text: "Invalid file format. Please select an Excel file (.xlsx or .xls).",
        icon: "error",
      });
      setFile(null); // Reset the file state if invalid
    } else {
      setFile(selectedFile); // Set file if valid
    }
  }
};


  // Function to handle file upload
  const handleFileUpload = async () => {
    if (!file) {
      Swal.fire({
        title: "Error",
        text: "Please select a file before uploading.",
        icon: "error",
      });
      return;
    }
  
    const formData = new FormData();
    formData.append("file", file);
  
    // Ensure a valid `id` is included
    const adminId = user?.auth === "admin-faculty" ? user._id : id;
    if (!adminId) {
      Swal.fire({
        title: "Error",
        text: "Invalid admin ID. Please check your session.",
        icon: "error",
      });
      return;
    }
  
    formData.append("id", adminId); // Include logged-in user's ID
  
    setIsLoading(true);
  
    try {
      const response = await axios.post(`${API_URL}/uploadStaff`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      Swal.fire({
        title: "Success!",
        text: response.data.message,
        icon: "success",
        confirmButtonText: "OK",
      });
  
      setShowModal(false);
      setFile(null);
    } catch (error) {
      console.error("Error uploading staff list:", error);
      Swal.fire({
        title: "Error!",
        text: error.response?.data?.message || "Failed to upload staff list. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
 	
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "excel.xlsx";
    link.click();
  };



// Utility function to generate random colors
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Prepare Data for Doughnut Chart
const batchLabels = getBatchData.map(batch => batch.batchName);
const batchValues = getBatchData.map(batch => batch.studentCount); // Assuming each batch has a 'studentCount' field
const backgroundColors = getBatchData.map(() => getRandomColor());

const chartData = {
  labels: batchLabels,
  datasets: [
    {
      data: batchValues,
      backgroundColor: backgroundColors,
      hoverBackgroundColor: backgroundColors,
    },
  ],
};

  const fetchBatchGet = async () => {
    try {
      const response = await axios.get(`${API_URL}/college-batch/${id}`);
     
      if (response.status === 200) {
        setGetBatchData(response.data.getBatch);
        // console.log(response.data.getBatch,"running......")
      }
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  useEffect(() => {
    fetchBatchGet();
  }, []);

  //-------------------staff dashboard-----------------------

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };

  const handleEdit = (batchId) => {
    navigate(`/lms/edit-batch/${batchId}`);
  };

  const handleDelete = async (batchId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this batch? This action cannot be undone.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.delete(`${API_URL}/batch/${batchId}`);
        if (response.status === 200) {
          Swal.fire('Deleted!', 'The batch has been deleted successfully.', 'success');
          fetchBatchGet(); // Refresh the batch list
        }
      } catch (error) {
        console.error("Error deleting batch:", error.response ? error.response.data : error.message);
        Swal.fire('Error!', 'There was an error deleting the batch. Please try again.', 'error');
      }
    }
  };


  return (
    <>
      <br/>
      <br/>
      <Container fluid className="dashboard-container mt-5">
      <div className="d-flex justify-content-between mb-3 mt-3">
  {/* Add Staff Button - Left-aligned */}
  {(user && (user.auth === "admin-faculty" || user.auth === "admin")) && (
    <Button
      variant="primary"
      onClick={() => setShowModal(true)}
    >
      <RiAddLargeLine /> Add Staff
    </Button>
  )}

  {/* Add Batch Button - Right-aligned */}
  <Button variant="success" onClick={() => navigate(`/lms/add-batch/${id}`)}>
    <RiAddLargeLine /> Add-Batch
  </Button>
</div>
   
   {/* Modal for File Upload */}
     
<Modal
  show={showModal}
  onHide={() => setShowModal(false)}
  centered
  style={{
    maxWidth: '90%',
    margin: '0 auto',
    marginTop: '5%',
    height: '600px', // Adjusts height dynamically based on content
  
  }}
  dialogClassName="custom-modal"
>
  <Modal.Body
    style={{
      padding: 'clamp(10px, 5%, 30px)', // Responsive padding
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'center',
      overflowY: 'auto', // Enables scrolling if content exceeds height
      marginTop: '30vh',

    }}
  >
    {/* Responsive Heading */}
    <h2
      style={{
        fontSize: 'clamp(1.5rem, 2.5vw, 2rem)', // Scalable font size
        fontWeight: 'bold',
        marginBottom: 'clamp(50px, 2%, 20px)', // Adjust spacing
        lineHeight: '1.4',
        color:"orange",
      
      }}
    >
      Upload Staff Excel File
    </h2>

    {/* Form Content */}
    <Form>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label style={{ fontSize: '1rem', fontWeight: '500' }}>
          Select Excel File
        </Form.Label>
        <Form.Control
          type="file"
          accept=".xlsx,.xls"
          onChange={handleFileChange}
          style={{ fontSize: '0.9rem' }}
        />
      </Form.Group>
      {/* {file && (
        <div style={{ marginTop: '10px', fontSize: '0.9rem' }}>
          Selected file: {file.name}
        </div>
      )} */}
      <Button
        className="btn mt-3"
        style={{
          backgroundColor: 'white',
          borderColor: 'white',
          color: 'green',
          fontSize: '0.9rem',
        }}
        onClick={handleDownload}
      >
        Download Excel Template
      </Button>
    </Form>
  </Modal.Body>

  <Modal.Footer
    style={{
      display: 'flex',
      justifyContent: 'right',
    }}
  >

<Button
      variant="primary"
      onClick={handleFileUpload}
      disabled={!file || isLoading}
      style={{ fontSize: '0.9rem' }}
    >
      {isLoading ? <Spinner animation="border" size="sm" /> : 'Upload File'}
    </Button>

    <Button
      variant="secondary"
      onClick={() => setShowModal(false)}
      style={{ fontSize: '0.9rem' }}
    >
      Close
    </Button>
    
  </Modal.Footer>
</Modal>

      <div className="dashboard-content d-flex">
      <div className="dashboard-image">
        <Lottie
          animationData={animation}
          // style={{ height: '400px', width: '300px' }} 
        />
      </div>

    
  <div className="dashboard-chart">
        <Doughnut data={chartData} options={options} />
        </div>
    </div>
    </Container>

     

      <br />
      <hr />
      
      <div className="Staff-dashboard-container">

{
  getBatchData.length > 0 ?  <> 
  {getBatchData.map((data )=> (
    <Card className="dashboard-card" key={data._id} onClick={()=> navigate(`/lms/student-dashboard/${data._id}`)}>
    <div className="card-section first-part">
      <div className="align-batch-details">
      <div className="batchnameHead">BatchName : {data.batchName}</div>
      <div className="card-row"><span className="batchname"> Department :</span>  {data.department}</div>
    </div>
    </div>

    <div className="card-section second-part">
    <div className="align-batch-details">
      <div className="card-row"><span className="batchname">Session : </span>{data.session}</div>
      <div className="card-row"><span className="batchname">Studying Year : </span>{data.studyingYear}</div>
      </div>
    </div>

    <div className="card-section third-part " >
    <div className="align-batch-details">

      <div className="student-count"><span className="batchname">Total Students :</span>  {data.studentCount}</div>
      </div>
      </div>
<div className="card-section icon-section ">
      <img className="arrowImage" src={ArrImage} alt="View Arrow" />

      <EditIcon
      className="editButton"
        fontSize="large"
        variant="contained"
        sx={{ color: "#2e9b99" }}
        onClick={(e) => {
          e.stopPropagation(); // Prevent the card's onClick event
          handleEdit(data._id);
        }}
      />

<DeleteForeverIcon
     className="editButton"
        fontSize="large"
        variant="contained"
        sx={{ color: "#EE4B2B" }}
        onClick={(e) => {
          e.stopPropagation(); // Prevent the card's onClick event
          handleDelete(data._id);
        }}
      />

    </div>
  </Card>
  )) }
  </> : <> <div style={{ 
  display: 'flex', 
  flexDirection:"column",
  justifyContent: 'center', 
  alignItems: 'center', 
  // Adjust height based on your requirement
  width: '100%' 
}}>
  <h2>No Data Found</h2>
  <Lottie
    animationData={NoDataFound}
    style={{ width: '500px' }}
  />
</div></>
}

</div>

<br/>
<Footer/>
    </>
  );
}
