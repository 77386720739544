import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Import axios to make API calls
import Swal from 'sweetalert2'; // Import SweetAlert2
import './ForgotPassword.css';
import { API_URL } from '../global';

const ForgotPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values) => {
      // Show loading SweetAlert
      Swal.fire({
        title: 'Sending Reset Link...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        // Make a POST request to the backend
        const response = await axios.post(`${API_URL}/forgotpassword`, {
          email: values.email,
        });

        // Handle success response
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: response.data.message,
          });
        }
      } catch (error) {
        // Handle error response
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error.response?.data?.message ||
            'An error occurred. Please try again later.',
        });
      }
    },
  });

  return (
    <div className="forgot-password-container">
      {/* Background video */}
      <video autoPlay muted loop className="forgot-background-video">
        <source
          src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1728560889/ix5ywxuuk4ii88i3l5su.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="row w-100">
          <div className="col-md-6 offset-md-3">
            <div className="card p-4">
              <h3 className="text-center mb-4">Forgot Password</h3>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="form-label"
                    style={{ color: 'white' }}
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      formik.touched.email && formik.errors.email
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="email"
                    placeholder="Enter your email"
                    {...formik.getFieldProps('email')}
                  />
                  {/* Error message */}
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>
                <button
                  type="submit"
                  className="btn btn-forgot"
                  style={{ width: '150px' }}
                >
                  <span className="Reset-btn">Send Reset Link</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
