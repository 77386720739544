import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { FaSort , FaPlus, FaEdit, FaTrash } from "react-icons/fa";
// import "./CollegeList.css"; // Add your styles here
import { FaUsersViewfinder } from "react-icons/fa6";
import { API_URL } from "../../global";
import Footer from "../Footer/Footer";





function CollegeList() {
  const navigate = useNavigate();
  const [colleges, setColleges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  useEffect(() => {
    fetchColleges();
  }, []);

  const fetchColleges = async () => {
    try {
      const response = await fetch(`${API_URL}/colleges`);
      const data = await response.json();
      setColleges(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching colleges:", error);
      setLoading(false);
      Swal.fire("Error", "Failed to fetch college data.", "error");
    }
  };

  const handleDelete = async (collegeId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(`${API_URL}/college/${collegeId}`, {
          method: "DELETE",
        });

        if (response.ok) {
          Swal.fire("Deleted!", "The college has been deleted.", "success");
          fetchColleges();
        } else {
          Swal.fire("Error", "Failed to delete the college.", "error");
        }
      } catch (error) {
        console.error("Error deleting college:", error);
        Swal.fire("Error", "An error occurred while deleting the college.", "error");
      }
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...colleges].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setColleges(sortedData);
  };

  return (
 <>
    <div className="college-list-container" style={{ marginTop: "85px" }}>
      
      <h2 className="text-center">College List</h2>
        
        <div className="d-flex justify-content-end mb-5" style={{marginRight:"30px"}} >
        <Button
        variant="success"
        onClick={() => navigate("/lms/collegeform")}
      >
        <FaPlus /> Add College
      </Button>
      </div >
     
    

    {loading ? (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" />
      </div>
    ) : (
      <Table responsive bordered hover>
        <thead>
          <tr>
            <th className="text-center">Sl.no</th>
            <th className="text-center" onClick={() => handleSort("collegeName")}>
              College Name 
            </th>
            <th  className="text-center" onClick={() => handleSort("collegeCode")}>
              College Code 
            </th>
            <th className="text-center" onClick={() => handleSort("email")}>
              College Email 
            </th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {colleges.length > 0 ? (
            colleges.map((college, index) => (
              <tr key={college._id}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{college.collegeName}</td>
                <td className="text-center">{college.collegeCode}</td>
                <td className="text-center">{college.email}</td>
                <td className="d-flex justify-content-around align-items-center">
                <Button
                    variant="secondary"
                    className="me-2"
                    onClick={() => navigate(`/lms/dashboard/${college._id}`)}
                  >
                   View <FaUsersViewfinder size={20}/>
                  </Button>

                  <Button
                    variant="warning"
                    className="me-2"
                    // onClick={() => navigate(`/lms/college/edit/${college._id}`)}
                  >
                    Edit <FaEdit />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(college._id)}
                  >
                   Delete <FaTrash />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No colleges found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )}
  </div>
<Footer/>
 </>
  );
}

export default CollegeList;
