import React, { createContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import {  Carousel } from "react-bootstrap";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [goToSlide, setGoToSlide] = useState(0); // Controls the current slide
  const [headers, setHeaders] = useState([]); // Dynamically stores headers for sections
  const [selectedModules, setSelectedModules] = useState([]); // Holds the modules of the clicked section
  const [chapters, setChapters] = useState([]);
  const [activeModuleIndex, setActiveModuleIndex] = useState(null); // New state for active module index
  const [activeSection, setActiveSection] = useState(null); // Track the active section
  const [activeIndex, setActiveIndex] = useState(0);
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false); // New state for controlling navbar collapse
  const [isCourseDropdownOpen, setIsCourseDropdownOpen] = useState(false); // State to handle dropdown toggle
  const [slides, setSlides] = useState([]); // Stores images for the carousel
  const [sections, setSections] = useState([]); // Stores sections data from MongoDB
  const [render, setRender] = useState(false)
  const [ courseHeader, setCourseHeader] = useState()
  const secretKey = "zuppalmssailearning";
  const token = "hide-videos-2621161601-2019-software";

  const handleSectionClick = (section) => {
    const displayPictures = section["DISPLAY PICTURE"] || [];
    const newSlides = displayPictures.map((url) => (
      <Carousel.Item key={url}>
        <img className="d-block w-100 carousel-img" src={url} alt="carousel-item" />
      </Carousel.Item>
    ));
    setSlides(newSlides);
    setGoToSlide(0);
  
    if (section.SECTIONS && section.SECTIONS[0]) {
      const sectionHeaders = section.SECTIONS.map((sec) => sec.NAME);
      setHeaders(sectionHeaders);
  
      // Default to the first module and set chapters
      const firstModule = section.SECTIONS[0].MODULES || [];
      setSelectedModules(firstModule);
      setChapters(firstModule[0]?.CHAPTERS || []);
      setActiveModuleIndex(0); // Automatically select the first module
      
    } else {
      setHeaders([]); // Reset if no sections
      setSelectedModules([]); // Reset if no modules
 
    }
  
    setActiveSection(section);
    setActiveIndex(0);
    localStorage.setItem("selectedSection", JSON.stringify(section));
   

    setIsNavbarExpanded(false);
    setIsCourseDropdownOpen(false);
    setRender((prev) => !prev); // Toggle render to force Labs.js re-render
  };
  

  // Function to encrypt and store user data
  const saveUser = (userData) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(userData),
      secretKey // Replace with your secret key
    ).toString();
    localStorage.setItem('user', encryptedData);
    setUser(userData);
  };

  const removeLocal = ()=> {
    localStorage.removeItem("selectedChapter");
    localStorage.removeItem("activeIndex");
    localStorage.removeItem("activeModuleIndex");
  }

  // Function to retrieve user data from localStorage
  useEffect(() => {
    const encryptedData = localStorage.getItem('user');
    if (encryptedData) {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUser(decryptedData);
    }
  }, []);

// console.log(user, "raja");

  return (
    <UserContext.Provider value={{ user, setUser, saveUser, handleSectionClick, sections, setSections, headers, setHeaders, slides, setSlides, render, setRender, goToSlide, setGoToSlide, selectedModules, setSelectedModules, chapters, setChapters, activeModuleIndex, setActiveModuleIndex, activeSection, setActiveSection, activeIndex, setActiveIndex, isNavbarExpanded, setIsNavbarExpanded, isCourseDropdownOpen, setIsCourseDropdownOpen, removeLocal,  courseHeader, setCourseHeader,token }}>
    {children}
    </UserContext.Provider>
  );

};

export default UserProvider;
