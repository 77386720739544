import React from "react";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "./Auth/Login.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Labs from "./Components/Labs/Labs.js";
import Video from "./Components/video/Video.js";
import Profile from "./Components/Profile/Profile.js";
import "bootstrap/dist/css/bootstrap.min.css";
import ForgotPassword from "./Auth/ForgotPassword.js";
import ResetPassword from "./Auth/ResetPassword.js";
import Dashboard from "./Components/Dashboard/Dashboard.js";
import BatchForm from "./Components/Dashboard/BatchForm.js";
import StudentDashboard from "./Components/Dashboard/StudentDashboard.js";
import CourseManagement from "./Components/Dashboard/CourseManagement.js";
import StaffEdit from "./Components/StaffEditPage/StaffEdit.js";
import Portal from "./Components/Portal/Portal.js";
import Homepage from "./Components/Home/Homepage.js";
import Footer from "./Components/Footer/Footer.js";
import ContactPage from "./Components/Contact/ContactPage.js";
import Collegeform from "./Components/Dashboard/Collegeform.js";
import CollegeList from "./Components/Dashboard/CollegeList.js";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute"; // Import your ProtectedRoute component


function App() {


  const location = useLocation(); // Get current route

  // Define routes where Footer should be hidden
  const hideFooterRoutes = ["/lms/login", "/lms/forgot-password", "/lms/reset-password"];


  return (
    <div>
      <Routes>
        {/* Redirect root path to /lms/homepage */}
        <Route path="/" element={<Navigate to="/lms/homepage" />} />
        <Route path="/lms" element={<Portal/>} >
        <Route path="login" element={<Login />} />
          <Route path="homepage" element={<Homepage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password/:id/:token" element={<ResetPassword />} />
          <Route path="contact" element={<ContactPage/>} />

           {/* Protected Routes */}
           <Route element={<ProtectedRoute />}>
          <Route path="dashboard/:id" element={<Dashboard />} />
          <Route path="student-dashboard/:id" element={<StudentDashboard />} />
          <Route path="assign-course/:id" element={<CourseManagement />} />
          <Route path="labs" element={<Labs />} />
          <Route path="video" element={<Video />} />
          <Route path="add-batch/:id" element={<BatchForm />} />
          <Route path="edit-batch/:id" element={<StaffEdit />} />
          <Route path="college" element={<CollegeList/>} />
          <Route path="collegeform" element={<Collegeform />} />

          <Route path="profile" element={<Profile />} />
          </Route>
        </Route>

        {/* <Route path="/collegeform" element={<Collegeform />} />
        <Route path="/labs" element={<Labs />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/student-dashboard/:id" element={<StudentDashboard />} />
        <Route path="/b" element={<BatchForm />} />
        <Route path="/c/:id" element={<CourseManagement />} />
        <Route path="/video" element={<Video />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/staff-edit/:id" element={<StaffEdit />} /> */}
      </Routes>

   {/* Conditionally render Footer */}
      {/* {!hideFooterRoutes.includes(location.pathname) && <Footer />} */}
    </div>
  );
}

export default App;
