import React, { useEffect, useState } from 'react';
import './StaffEdit.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Footer from '../Footer/Footer';
import Swal from "sweetalert2";
import { API_URL } from '../../global';



const StaffEdit = () => {
  const { id } = useParams(); // Get the batch ID from the URL
  const navigate = useNavigate();
  const [batchData, setBatchData] = useState(null); // Change initial state to null

  useEffect(() => {
    const fetchBatchData = async () => {
      try {
        const response = await fetch(`${API_URL}/batch/${id}`, {
          method: "GET",
        });
        const data = await response.json();
        setBatchData(data.getBatch);
        // console.log(data.getBatch)
      } catch (error) {
        console.error("Error fetching batch data:", error);
      }
    };

    fetchBatchData();
  }, [id]);

      //*  behavior: "smooth", */
      useEffect(() => {
        const body = document.querySelector("#root");
        body.scrollIntoView(
          {
            behavior: "smooth",
          },
          500
        );
        window.scrollTo(0, 0);
      }, []);

  const validationSchema = Yup.object().shape({
    batchName: Yup.string().required("Batch Name is required"),
    session: Yup.string().required("Session is required"),
    studyingYear: Yup.string().required("Studying Year is required"),
    department: Yup.string().required("Department is required"),
    startYear: Yup.number().required("Start Year is required").integer("Start Year must be an integer"),
    endYear: Yup.number()
      .required("End Year is required")
      .integer("End Year must be an integer")
      .test("is-greater", "End Year must be greater than Start Year", function (value) {
        const { startYear } = this.parent;
        return startYear && value > startYear;
      }),
  });

  const handleSubmit = async (values) => {
    try {
      // Show SweetAlert2 loading state
      Swal.fire({
        title: "Updating...",
        text: "Please wait while we update the batch details.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Show the loading spinner
        },
      });
  
      const response = await fetch(`${API_URL}/batch/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        // Replace loading spinner with an error message
        Swal.fire({
          icon: "error",
          title: "Update Failed",
          text: data.message || "Something went wrong! Please try again.",
        });
      } else {
        // Replace loading spinner with a success message
        Swal.fire({
          icon: "success",
          title: "Updated Successfully!",
          text: "The batch details have been updated.",
        });
  
        navigate(-1); // Redirect to dashboard on success
      }
    } catch (error) {
      console.error("Error updating batch:", error);
  
      // Replace loading spinner with an error message
      Swal.fire({
        icon: "error",
        title: "Update Failed",
        text: "Unable to update batch details. Please try again later.",
      });
    }
  };
  

  // Show a loading message while batch data is being fetched
  if (batchData === null) {
    return <div>Loading...</div>;
  }




  return (
<>
<div style={{marginTop:"50px"}}>
<Formik
        initialValues={{
            batchName: batchData.batchName || "",
            session: batchData.session || "",
            studyingYear: batchData.studyingYear || "",
            department: batchData.department || "",
            startYear: batchData.startYear || "",
            endYear: batchData.endYear || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
    >
        {({ errors, touched }) => (
            <Form>
                <div className="container batch-form-container mt-5">
                    <h2 className="editbatchTag text-center">Edit Batch Form</h2>
                    <div className="row batch-form-row">
                        <div className="col-md-6 batch-form-input-data">
                            <label>Batch Name</label>
                            <Field
                                type="text"
                                name="batchName"
                                className={`form-control ${touched.batchName && errors.batchName ? "input-error" : ""}`}
                            />
                            <ErrorMessage name="batchName" component="div" className="error" />
                        </div>
                        <div className="col-md-6 batch-form-input-data">
                            <label>Session</label>
                            <Field as="select" name="session" className={`form-control ${touched.session && errors.session ? "input-error" : ""}`}>
                                <option value="">Select Session</option>
                                <option value="session1">Morning</option>
                                <option value="session2">Afternoon</option>
                                <option value="session3">Evening</option>
                            </Field>
                            <ErrorMessage name="session" component="div" className="error" />
                        </div>
                    </div>
                    <div className="row batch-form-row">
                        <div className="col-md-6 batch-form-input-data">
                            <label>Start Year</label>
                            <Field as="select" name="startYear" className="form-control">
                                <option value="" disabled>Select Start Year</option>
                                {Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Field>
                            <ErrorMessage name="startYear" component="div" className="error" />
                        </div>
                        <div className="col-md-6 batch-form-input-data">
                            <label>End Year</label>
                            <Field as="select" name="endYear" className="form-control">
                                <option value="" disabled>Select End Year</option>
                                {Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Field>
                            <ErrorMessage name="endYear" component="div" className="error" />
                        </div>
                    </div>
                    <div className="row batch-form-row">
                        <div className="col-md-6 batch-form-input-data">
                            <label>Department</label>
                            <Field as="select" name="department" className={`form-control ${touched.department && errors.department ? "input-error" : ""}`}>
                                <option value="">Select Department</option>
                                <option value="department1">Aerospace</option>
                                <option value="department2">Aeronautical</option>
                                <option value="department3">B.Tech IT</option>
                                <option value="department4">B.Tech CSE</option>
                                <option value="department5">CSE</option>
                                <option value="department6">EEE</option>
                                <option value="department7">ECE</option>
                                <option value="department8">IT</option>
                                <option value="department9">Mech</option>
                            </Field>
                            <ErrorMessage name="department" component="div" className="error" />
                        </div>
                        <div className="col-md-6 batch-form-input-data">
                            <label>Studying Year</label>
                            <Field as="select" name="studyingYear" className={`form-control ${touched.studyingYear && errors.studyingYear ? "input-error" : ""}`}>
                                <option value="" disabled>Select Year</option>
                                <option value="1st Year">1st Year</option>
                                <option value="2nd Year">2nd Year</option>
                                <option value="3rd Year">3rd Year</option>
                                <option value="4th Year">4th Year</option>
                            </Field>
                            <ErrorMessage name="studyingYear" component="div" className="error" />
                        </div>
                    </div>
                    <div className="batch-form-submit-btn">
                        <Button type="submit" variant="contained" color="primary">UPDATE</Button>
                    </div>
                </div>
            </Form>
        )}
    </Formik>
    <br/>
    
</div>

<Footer/>
</>
);

};

export default StaffEdit;
