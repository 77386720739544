import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import "./ResetPassword.css";
import { API_URL } from '../global';

const ResetPassword = () => {
  const { id, token } = useParams(); // Get id and token from URL parameters
  const navigate = useNavigate(); // Initialize useNavigate

  // Formik setup
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(4, 'Password must be at least 4 characters')
        .required('New Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: async (values) => {
      Swal.fire({
        title: 'Processing...',
        text: 'Please wait while we reset your password.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const response = await axios.post(`${API_URL}/resetpassword/${id}/${token}`, {
          password: values.newPassword,
        });

        if (response.data.statusCode === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: response.data.message,
          }).then(() => {
            navigate('/lms/login'); // Navigate to login page after success
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while resetting the password. Please try again.',
        });
      }
    },
  });

  // Scroll to top when component mounts
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="reset-password-container" style={{ marginTop: "75px" }}>
      <video autoPlay muted loop className="reset-background-video">
        <source
          src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1728560889/ix5ywxuuk4ii88i3l5su.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="row w-100">
          <div className="col-md-6 offset-md-3">
            <div className="reset-card p-4">
              <h3 className="text-center mb-4">Reset Password</h3>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="newPassword" className="form-label" style={{ color: 'white' }}>
                    New Password
                  </label>
                  <input
                    type="password"
                    className={`form-control ${formik.touched.newPassword && formik.errors.newPassword ? 'is-invalid' : ''}`}
                    id="newPassword"
                    placeholder="Enter your new password"
                    {...formik.getFieldProps('newPassword')}
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="text-danger">{formik.errors.newPassword}</div>
                  ) : null}
                </div>

                <div className="mb-4">
                  <label htmlFor="confirmPassword" className="form-label" style={{ color: 'white' }}>
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'is-invalid' : ''}`}
                    id="confirmPassword"
                    placeholder="Confirm your new password"
                    {...formik.getFieldProps('confirmPassword')}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="text-danger">{formik.errors.confirmPassword}</div>
                  ) : null}
                </div>

                <button type="submit" className="btn btn-reset" style={{ width: '150px' }}>
                  <span className="Reset-btn">Reset Password</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
