import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Collegeform.css";
import Swal from "sweetalert2";
import { API_URL } from "../../global";
import Footer from "../Footer/Footer";

// Yup validation schema
const validationSchema = Yup.object({
  collegeName: Yup.string().required("College Name is required"),
  collegeCode: Yup.string().required("College Code is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
    .required("Phone Number is required"),
  address: Yup.string().required("Address is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

function Collegeform() {
  const formik = useFormik({
    initialValues: {
      collegeName: "",
      collegeCode: "",
      phoneNumber: "",
      address: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        Swal.fire({
          title: "Submitting...",
          text: "Please wait while we upload your college details.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(`${API_URL}/college`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        });

        const result = await response.json();

        if (!response.ok) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result.message || "Something went wrong!",
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "College details successfully uploaded.",
          });
          resetForm(); // Reset the form on success
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while submitting the form. Please try again.",
        });
      }
    },
  });


  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    window.scrollTo(0, 0);
  }, []);


  return (
 <>
 
 <div className="form-container" style={{marginTop:"75px"}}>
      <h2 className="collageheading ">College Information Form</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="collegeName">College Name</label>
          <input
            type="text"
            id="collegeName"
            name="collegeName"
            value={formik.values.collegeName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter college name"
            required
          />
          {formik.touched.collegeName && formik.errors.collegeName && (
            <div className="text-danger">{formik.errors.collegeName}</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="collegeCode">College Code</label>
          <input
            type="text"
            id="collegeCode"
            name="collegeCode"
            value={formik.values.collegeCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter college number"
            required
          />
          {formik.touched.collegeCode && formik.errors.collegeCode && (
            <div className="text-danger">{formik.errors.collegeCode}</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter phone number"
            required
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className="text-danger">{formik.errors.phoneNumber}</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="address">Address</label>
          <textarea
            id="address"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter address"
            required
          />
          {formik.touched.address && formik.errors.address && (
            <div className="text-danger">{formik.errors.address}</div>
          )}
        </div>

        {/* Email and Password fields side by side */}
        <div className="form-group flex">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter email"
              required
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-danger">{formik.errors.email}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter password"
              required
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-danger">{formik.errors.password}</div>
            )}
          </div>
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
    <Footer/>
 </>
  );
}

export default Collegeform;
