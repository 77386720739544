import React, { useEffect, useState, useRef, useContext } from "react";
import { Carousel } from "react-bootstrap";
import axios from "axios";
// import { config } from "react-spring";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Labs.css";
import { UserContext } from "../../context/UserContext";
import { API_URL } from "../../global";
import Footer from "../Footer/Footer";

export default function Labs() {
  const {
    user,
    sections,
    setSections,
    headers,
    slides,
    handleSectionClick,
    setGoToSlide,
    selectedModules,
    setSelectedModules,
    chapters,
    setChapters,
    activeModuleIndex,
    setActiveModuleIndex,
    activeSection,
    activeIndex,
    setActiveIndex,
    setIsCourseDropdownOpen,
  } = useContext(UserContext);
  // const role = localStorage.getItem("role");
  // const id = localStorage.getItem("id")

  //const [sections, setSections] = useState([]); // Stores sections data from MongoDB
  // const [slides, setSlides] = useState([]); // Stores images for the carousel
  // const [goToSlide, setGoToSlide] = useState(0); // Controls the current slide
  // const [offsetRadius] = useState(2);
  // const [animationConfig] = useState(config.gentle);
  // const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0); 
  // const [headers, setHeaders] = useState([]); // Dynamically stores headers for sections
  // const [selectedModules, setSelectedModules] = useState([]); // Holds the modules of the clicked section
  // const [activeSection, setActiveSection] = useState(null); // Track the active section
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  const dropdownRef = useRef(null); // Ref to the dropdown
  // const [chapters, setChapters] = useState([]);
  // const [activeIndex, setActiveIndex] = useState(0);
  // const [activeModuleIndex, setActiveModuleIndex] = useState(null); // New state for active module index
  // const [menuHide, setMenuHide] = useState()
  //const [isNavbarExpanded, setIsNavbarExpanded] = useState(false); // New state for controlling navbar collapse
  // const [isCourseDropdownOpen, setIsCourseDropdownOpen] = useState(false); // State to handle dropdown toggle
  const courseDropdownRef = useRef(null); // Ref to the dropdown
  // const [isCourseAccordionOpen, setIsCourseAccordionOpen] = useState(false);
  // const [getStudentData, setGetStudentData] = useState([])
  // const [courseCounts, setCourseCounts] = useState({});
  // const [studentCourse, setStudentCourse] = useState([])
  // const [chapterProgress, setChapterProgress] = useState([]); // Store progress for each chapter
  const [videoProgress, setVideoProgress] = useState({}); // Store video progress for each chapter
  const [videoDurations, setVideoDurations] = useState({}); // Store video durations for each chapter
  const videoRef = useRef(null); // Ref for the hidden video element
  const [selectedModuleName, setSelectedModuleName] = useState(
    localStorage.getItem("selectedModuleName") || "Trouble Shooting" // Load from localStorage or default value
  );

  const location = useLocation();


  // const handleList = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleListClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleCourseClick = () => {
  //   if (isNavbarExpanded) {
  //     // If in mobile view (navbar expanded), toggle accordion
  //     setIsCourseAccordionOpen(!isCourseAccordionOpen);
  //   } else {
  //     // If in desktop view (navbar collapsed), toggle dropdown
  //     setIsCourseDropdownOpen(!isCourseDropdownOpen);
  //   }
  // };

  // Fetch video progress data based on section and module
  const fetchStudentProgress = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/student-videoprogress/${user.user_id}`
      );
      const studentData = response.data?.getStudents?.[0] || null;
      const sectionName = headers[activeIndex];
      const moduleName = selectedModules[activeModuleIndex]?.NAME;

      if (studentData) {
        const progress = getProgressForSectionAndModule(
          studentData,
          sectionName,
          moduleName
        );
        setVideoProgress(progress);
      }
    } catch (error) {
      console.error("Error fetching video progress:", error);
    }
  };

  useEffect(() => {
    if (user) fetchStudentProgress();
  }, [user, selectedModuleName, activeModuleIndex]);

  useEffect(() => {
    if (user && location.pathname === "/lms/labs") {
      fetchStudentProgress();
    }
  }, [location, user]);

  // Helper function to extract progress for the active section and module
  const getProgressForSectionAndModule = (
    studentData,
    sectionName,
    moduleName
  ) => {
    let progressData = {};

    studentData.courses.forEach((course) => {
      course.progress.forEach((section) => {
        if (section.sectionName === sectionName) {
          section.modules.forEach((module) => {
            if (module.moduleName === moduleName) {
              module.chapters.forEach((chapter) => {
                progressData[chapter.chapterName] = chapter.videoWatched || 0;
              });
            }
          });
        }
      });
    });

    return progressData;
  };

  // Load video duration if not already loaded
  const loadVideoDuration = (videoUrl, chapterName) => {
    if (videoUrl && videoRef.current && !videoDurations[chapterName]) {
      videoRef.current.src = videoUrl;
      videoRef.current.onloadedmetadata = () => {
        setVideoDurations((prev) => ({
          ...prev,
          [chapterName]: videoRef.current.duration,
        }));
      };
    }
  };

  // Detect outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        courseDropdownRef.current &&
        !courseDropdownRef.current.contains(event.target)
      ) {
        setIsCourseDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [courseDropdownRef]);

  // const fetchStudentGet = useCallback (async () => {
  //   try {
  //     const response = await axios.get(`http://localhost:4000/student/${user.batch_id}`);
  //    console.log(response.data.getStudents,"course data")
  //     if (response.status === 200) {
  //       setGetStudentData(response.data.getStudents);
  //       calculateCourseCounts(response.data.getStudents);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching sections:", error);
  //   }
  // },[user.batch_id]);

  // const calculateCourseCounts = (students) => {
  //   const counts = {};
  //   const allCourses = []; // Aggregate all courses

  //   students.forEach(student => {
  //     student.courses.forEach(course => {
  //       counts[course] = (counts[course] || 0) + 1;
  //       if (!allCourses.includes(course)) { // Avoid duplicate courses
  //         allCourses.push(course);
  //       }
  //     });
  //   });
  //   setCourseCounts(counts);
  //   setStudentCourse(allCourses); // Update state with the entire array of courses at once
  //   console.log(allCourses, "Collected courses...");
  // };

  // useEffect(() => {
  //   fetchStudentGet(); // Fetch student data when component mounts
  // }, [fetchStudentGet]);

  // useEffect(() => {

  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get("http://localhost:4000/innovation");
  //       if (response.data && Array.isArray(response.data)) {
  //         const filteredSections = response.data.filter(section =>
  //           studentCourse.includes(section._id)
  //         );
  //         setSections(filteredSections);
  //         console.log("Filtered Sections: ", filteredSections);
  //       } else {
  //         console.error("Unexpected response format:", response.data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching sections:", error);
  //     }
  //   };

  //   if (studentCourse.length > 0) { // Fetch sections only when studentCourse is updated
  //     fetchData();
  //   }
  // }, [studentCourse]); // Trigger only when studentCourse updates

  useEffect(() => {
    if (user.role && user.role !== "student") {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${API_URL}/innovation`);
          if (response.data && Array.isArray(response.data)) {
            setSections(response.data);
          } else {
            console.error("Unexpected response format:", response.data);
          }
        } catch (error) {
          console.error("Error fetching sections:", error);
        }
      };

      fetchData();
    }
  }, [user.role]); // Add role as a dependency

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get("http://localhost:4000/innovation");
  //       if (response.data && Array.isArray(response.data)) {
  //         setSections(response.data);
  //       } else {
  //         console.error("Unexpected response format:", response.data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching sections:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  // Function to handle header click and update the carousel

  // const handleSectionClick = (section) => {
  //   const displayPictures = section["DISPLAY PICTURE"] || [];
  //   const newSlides = displayPictures.map((url) => (
  //     <Carousel.Item key={url}>
  //       <img className="d-block w-100 carousel-img" src={url} alt="carousel-item" />
  //     </Carousel.Item>
  //   ));
  //   setSlides(newSlides);
  //   setGoToSlide(0);

  //   if (section.SECTIONS && section.SECTIONS[0]) {
  //     const sectionHeaders = section.SECTIONS.map((sec) => sec.NAME);
  //     setHeaders(sectionHeaders);

  //     // Default to the first module and set chapters
  //     const firstModule = section.SECTIONS[0].MODULES || [];
  //     setSelectedModules(firstModule);
  //     setChapters(firstModule[0]?.CHAPTERS || []);
  //     setActiveModuleIndex(0); // Automatically select the first module
  //   } else {
  //     setHeaders([]); // Reset if no sections
  //     setSelectedModules([]); // Reset if no modules
  //   }

  //   setActiveSection(section);
  //   setActiveIndex(0);
  //   localStorage.setItem("selectedSection", JSON.stringify(section));
  //   setIsNavbarExpanded(false);
  //   setIsCourseDropdownOpen(false);
  // };

  const handleHeaderClick = (index) => {
    if (
      activeSection &&
      activeSection.SECTIONS &&
      activeSection.SECTIONS[index] &&
      activeSection.SECTIONS[index].MODULES
    ) {
      const modules = activeSection.SECTIONS[index].MODULES;
      setSelectedModules(modules);
      setIsDropdownOpen(!isDropdownOpen);
      // setActiveModuleIndex(0); // Automatically select the first module
      // setSelectedModuleName(modules[0]?.NAME || ""); // Set the name of the first module
      // setChapters(modules[0]?.CHAPTERS || []); // Update chapters with the first module's chapters
      localStorage.setItem("activeModuleIndex", 0); // Store the updated module index in localStorage
      localStorage.setItem("selectedModuleName", modules[0]?.NAME || ""); // Store the updated module name
    
      
    } else {
      console.error("Invalid section or no modules found.");
      setSelectedModules([]); // Reset to empty if no modules are found
    }
    setActiveIndex(index);
    localStorage.setItem("activeIndex", index); // Save the active index to localStorage
  };



  const handleModuleClick = (module, moduleIndex) => {
    setChapters(module.CHAPTERS);
    setActiveModuleIndex(moduleIndex);
    setSelectedModuleName(module.NAME);
    // Store the clicked module index in localStorage
    localStorage.setItem("activeModuleIndex", moduleIndex);
    localStorage.setItem("selectedModuleName", module.NAME);

  };
  const handleChapterClick = (chapter, module) => {
    // Ensure activeIndex points to the correct section in headers
    const sectionName = headers[activeIndex]; // Get section name from headers array using activeIndex
    const moduleName = module?.NAME;
    const chapterName = chapter?.NAME;
    const courseId = activeSection?._id; // Assuming activeSection holds course info

    // Structure data to store in localStorage
    const chapterData = {
      courseId,
      sectionName,
      moduleName,
      chapterName,
      chapter,
    };

    // Store structured chapter data in localStorage
    localStorage.setItem("selectedChapter", JSON.stringify(chapterData));

    // Navigate to the Video component
    navigate("/lms/video");
  };

 useEffect(() => {
  const savedSection = JSON.parse(localStorage.getItem("selectedSection"));
  const savedActiveIndex = localStorage.getItem("activeIndex");
  const savedModuleIndex = localStorage.getItem("activeModuleIndex");

  if (location.pathname === "/lms/labs") {
    if (savedSection && sections.length > 0) {
      handleSectionClick(savedSection);
    }

    if (savedActiveIndex !== null) {
      setActiveIndex(Number(savedActiveIndex));
      const savedModules =
        savedSection?.SECTIONS[Number(savedActiveIndex)]?.MODULES || [];
      setSelectedModules(savedModules);

      if (savedModuleIndex !== null && savedModules.length > 0) {
        setActiveModuleIndex(Number(savedModuleIndex));
        setChapters(savedModules[Number(savedModuleIndex)]?.CHAPTERS || []);
      }
    }
  }
}, [location.pathname, sections]);


  // // Re-render Labs.js whenever 'render' changes
  // useEffect(() => {
  //   // Add any logic here that should happen on re-render
  //   console.log("Labs.js component re-rendered due to render state change");
  // }, [render]);

  useEffect(() => {
    if (slides.length > 0) {
      // Auto-slide functionality only if there are slides
      const interval = setInterval(() => {
        setGoToSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 5000); // Change every 5 seconds

      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [slides]);

 

  // const handleShow = () => setShowModal(true);
  // const handleClose = () => setShowModal(false);

  // const handleClick = (index) => {
  //   setActiveIndex(index);
  // };
  // // Handle opening and closing of the dropdown
  // const handleToggle = () => {
  //   setIsDropdownOpen((prevState) => !prevState);
  // };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // const videoPage = () => {
  //   navigate("/video");
  // };

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    window.scrollTo(0, 0);
  }, [headers]);

  return (
    <>
      <main style={{ marginTop: "75px" }}>
        <video ref={videoRef} style={{ display: "none" }} />
        {/* Carousel component */}
        <div className="carousel-container" style={{ maxWidth: "100%" }}>
          {slides.length > 0 ? (
            <Carousel interval={3000} indicators={true} controls={true}>
              {slides}
            </Carousel>
          ) : (
            <img
              style={{
                display: "block",
                width: "100%",
              }}
              src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1728296202/xk9dyvsvbpbqfscbsrhp.png"
              alt="Home page"
            />
          )}
        </div>

        {/* Dynamic headers and modules */}
        <div className="header-container">
          <ul className="header-list">
            {headers.map((header, index) => (
              <li
                key={index}
                className={`header-item ${
                  activeIndex === index ? "active" : ""
                }`}
                onClick={() => handleHeaderClick(index)} // Pass the clicked index
              >
                <h6>{header}</h6>
                {activeIndex === index && <div className="active-bar"></div>}

                {activeIndex === index && (
                  <div
                    ref={dropdownRef}
                    className={`modules-container ${
                      isDropdownOpen ? "show" : ""
                    }`}
                  >
                    {selectedModules.length > 0
                      ? selectedModules.map((module, moduleIndex) => (
                          <div
                            key={moduleIndex}
                            className={`module-item ${
                              activeModuleIndex === moduleIndex ? "active" : ""
                            }`}
                            onClick={() =>
                              handleModuleClick(module, moduleIndex)
                            }
                          >
                            <h6>{module.NAME}</h6>
                          </div>
                        ))
                      : null}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div class="logo-container">
  <h4 className="modulename">{selectedModuleName}</h4>
</div>
        {chapters && chapters.length > 0 && (
          <div className="container">
            <div className="Mroimage-card">
              {chapters.map((chapter, chapterIndex) => {
                const videoUrl = `${chapter.VIDEO_URL}` || "Loading....."; // Replace with actual video URL
                const duration = videoDurations[chapter.NAME];

                // Load duration only if it's not already loaded
                if (!duration) loadVideoDuration(videoUrl, chapter.NAME);

                return (
                  <div
                    className="Mroimage-card-content"
                    key={chapterIndex}
                    onClick={() =>
                      handleChapterClick(
                        chapter,
                        selectedModules[activeModuleIndex]
                      )
                    }
                  >
                    <div className="MroImage-container">
                      <img
                        src={chapter.PICTURE ? chapter.PICTURE : null}
                        alt="thumbnail"
                        className="Mrocard-image"
                        style={{ width: "200px", height: "200px" }}
                      />
                      <br />
                      <br />
                      <ProgressBar
                        style={{ height: "10px" }}
                        now={videoProgress[chapter.NAME] || 0}
                        label={`${videoProgress[chapter.NAME] || 0}%`}
                        visuallyHidden
                        animated
                        variant="warning"
                      />
                    </div>
                    <div className="Mrocard-details">
                      <h2>{chapter.NAME}</h2>
                      <div className="inline-span-container" style={{width:'240px', justifyContent:'center', marginLeft:'-20px'}}>
                        <span className="Mrocard-detailsOne">
                          {" "}
                          Duration:{" "}
                          {typeof duration === "number"
                            ? `${Math.floor(duration / 60)}:${Math.floor(
                                duration % 60
                              )}`
                            : "Loading..."}
                        </span>
                        <span className="Mrocard-detailsTwo">
                          {" "}
                          Completion: {videoProgress[chapter.NAME] || 0} %
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </main>

      <br />
      <Footer/>
    </>
  );
}
