import React, { useEffect } from "react";
import "./Footer.css";
import linkedin from "../../assets/linkedin.png";
import website from "../../assets/ux.png";
import youtube from "../../assets/youtube.png";
import email from "../../assets/gmail.png";
import {Fade} from "react-awesome-reveal"
import { Link } from "react-router-dom";

const Footer = () => {

  useEffect(() => {
    const canvas = document.getElementById("test");
    const ctx = canvas.getContext("2d");
    const imageUrls = [
      "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1729330619/gsuvwubynbf4kjifhyex.gif",
    ];
    const images = [];
    const arc = 8;
    const size = 100;
    const speed = 20;
    let parts = [];
    let time = 70;
    let mouse = { x: 0, y: 0 };

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Load images
    const loadImages = (urls) => {
      return Promise.all(
        urls.map((url) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(img);
          });
        })
      );
    };

    loadImages(imageUrls).then((loadedImages) => {
      images.push(...loadedImages);
      createParticles();
      drawParticles();
    });

    function createParticles() {
      for (let i = 0; i < arc; i++) {
        parts[i] = {
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          toX: Math.random() * 5 - 1,
          toY: Math.random() * 2 - 1,
          img: images[Math.floor(Math.random() * images.length)],
          size: Math.random() * size,
        };
      }
    }

    function drawParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.addEventListener("mousemove", (e) => {
        mouse.x = e.layerX;
        mouse.y = e.layerY;
      });

      parts.forEach((p) => {
        const distanceFactor = Math.max(
          Math.min(15 - DistanceBetween(mouse, p) / 10, 10),
          1
        );
        ctx.drawImage(
          p.img,
          p.x,
          p.y,
          p.size * distanceFactor,
          p.size * distanceFactor
        );

        p.x += p.toX * (time * 0.01);
        p.y += p.toY * (time * 0.02);

        if (p.x > canvas.width) p.x = 0;
        if (p.y > canvas.height) p.y = 0;
        if (p.x < 0) p.x = canvas.width;
        if (p.y < 0) p.y = canvas.height;
      });

      if (time < speed) time++;

      requestAnimationFrame(drawParticles);
    }

    function DistanceBetween(p1, p2) {
      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;
      return Math.sqrt(dx * dx + dy * dy);
    }

    return () => {
      canvas.removeEventListener("mousemove", () => {
        
      });
    };
  }, []);

  return (
<>
<footer>

      <div className="footer-wrapper">
        <canvas className="footercanvas" id="test"></canvas>
        <div className="footer-container">   
<div className="zuppanametag">
<Fade direction="left">
<img className="footerlogoimage"  src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1735795527/zkvojccmuawxgh9eetf4.png" />

</Fade>
<h4 className="footerFindMain">Zuppa Geo Navigation Technologies </h4>
            <p className="privatelimited"> Private Limited</p>
        
</div>
          <div className="footer-center">
         <br/>
            <h3 className="footerFind">Reach Out</h3>


            <div className="footer-buttons">
              <Link
                to="https://www.linkedin.com/company/zuppa-drones/posts/?feedView=all"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="LinkedIn" />
              </Link>

              <Link
                to="https://shop.zuppa.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={website} alt="Website" />
              </Link>

              <Link
                to="https://www.youtube.com/@zuppageonavtech636"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="YouTube" />
              </Link>
              <a
                href="mailto:askme@zuppa.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={email} alt="Email Icon" />
              </a>

            </div>
          </div>

          <br />
          <div className="footer-right">
            <p>
              {" "}
              <h3 className="findtag"> Find us</h3>
            </p>

            <p> PolyHose Tower No:86, </p>
            <p> West Wing, 4th Floor,</p>
            <p> AnnaSalai, Guindy Chennai-600032</p>
         
          </div>
          <img
            src="https://media.tenor.com/IF2JdxzmyN4AAAAi/coding-girl.gif"
            alt="Coding girl"
            className="footer-imagetwo"
          />
        </div>
      </div>
      <div className="copyright">
        {" "}
        <p className="mt-2">© 2024 Zuppa Drone LMS. All rights reserved.</p>
      </div>
    
</footer>
</>
  );
};

export default Footer;




