import React, { useState, useEffect } from "react";
import "./Homepage.css";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";


function Homepage() {
  const navigate = useNavigate();
  const phrases = [
    "500+ Hours of Drone Training Content",
    "Easy-to-Use Indian-Made Drones",
    "Empowering Future Innovators",
  ];
  const accordionData = [
    {
      title: "What makes Zuppa LMS ideal for educational institutions?",
      content:
        "Zuppa LMS is specifically designed to equip institutions with everything they need to teach drone technology effectively, including 500+ hours of expert-curated content and hands-on support for lab setup using Indian-made drones",
    },
    {
      title: "What type of support does Zuppa offer for drone labs?",
      content:
        "Zuppa provides end-to-end solutions, including supplying drones, assisting with lab setup, and offering continuous training and technical support to institutions.",
    },
    {
      title: "Why should Educational institutions focus on drone education?",
      content:
        "The drone industry is rapidly growing, with applications across sectors like agriculture, defense, and logistics. By adopting Zuppa LMS, institutions empower students with cutting-edge knowledge and skills, preparing them for lucrative opportunities in this emerging field.",
    },
    {
      title: "Why are Zuppa’s drones ideal for educational purposes?",
      content:
        "Our drones are cyber-secure, user-friendly, and designed specifically for educational institutions, making them perfect for introducing students to practical drone applications.",
    },
  ];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [isTyping, setIsTyping] = useState(true); // Determines if typing or erasing

  const [activeIndex, setActiveIndex] = useState(null); // Track which accordion is open

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close if it's already open
    } else {
      setActiveIndex(index); // Open the clicked accordion
    }
  };
  useEffect(() => {
    const currentPhrase = phrases[currentPhraseIndex];

    if (isTyping) {
      // Typing effect
      if (displayedText.length < currentPhrase.length) {
        const typingTimeout = setTimeout(() => {
          setDisplayedText(
            currentPhrase.substring(0, displayedText.length + 1)
          );
        }, 100);
        return () => clearTimeout(typingTimeout);
      } else {
        // Pause before erasing
        const pauseTimeout = setTimeout(() => setIsTyping(false), 1500);
        return () => clearTimeout(pauseTimeout);
      }
    } else {
      // Erasing effect
      if (displayedText.length > 0) {
        const erasingTimeout = setTimeout(() => {
          setDisplayedText(
            currentPhrase.substring(0, displayedText.length - 1)
          );
        }, 50);
        return () => clearTimeout(erasingTimeout);
      } else {
        // Move to the next phrase
        setIsTyping(true);
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
      }
    }
  }, [displayedText, isTyping, currentPhraseIndex]);

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="hero-container">
        {/* Text Section */}
        <div className="hero-text">
          <h2 className="hero-subheading">
            Revolutionizing Education, One Flight at a Time
          </h2>
          <h1 className="hero-heading">{displayedText}</h1>
          <h2 className="hero-subheading">
            Join the Drone Education Revolution Today!
          </h2>
          <div className="hero-buttons">
            <button
              className="hero-button"
              onClick={() => navigate("/lms/contact")}
            >

              Join / Contact us
            </button>
          </div>
        </div>
        <img
          className="hero-image"
          src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1732276949/xnvrcvmlglei2uplmrza.png"
          alt="Dream Visual"
        />
      </div>
      <br />
      <br />
   
<div className="container" >
  <div className="content aboutpage ">
  
      <h2 className="section-heading text-center">ABOUT US</h2>

      <p>At Zuppa Geo Navigation Technologies, we are redefining the boundaries of innovation in drone education. As <span style={{fontWeight:'bold', fontSize:'20px', color:'#fc8d04'}}>India’s only company with a patented computing architecture for drone autopilots </span>, we lead the way in technological advancements and education solutions.</p>
      <p>Exclusive Indian manufacturer of cyber-secure drones</p>
      <p>Trusted supplier of drone autopilots to DRDO and DPSUs</p>
      <p> India’s first internationally certified aerospace manufacturer for drones and electronics.</p>
      <p>With <span style={{fontWeight:'bold', fontSize:'20px', color:'#fc8d04'}}>Zuppa Learning Management System (ZLMS)</span> ,we bridge the gap between academia and technology. Our platform offers 500+ hours of engaging video content, enabling educational institutions to set up drone labs and nurture the next generation of drone professionals.</p>
   
  </div>
  <div className="image-wrapper">

    <img
      className="image"
      src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1732512524/kc6kzjy8t58wusfmrlrn.png"
      alt="About Us"
    />

  </div>
</div>
<br/>
<br/>
{/* Second Section: Left Image, Right Content */}
<div className="container reverse">
  <div className="image-wrapper">

  <img
    src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1732512983/hp5gspf4nuatcahuwggu.png"
    alt="3D Effect"
    className="image"
  />
{/* </div> */}
  </div>
  <div className="content aboutPagetwo">
    <h2 className="section-heading">WHY CHOOSE OUR LMS?</h2>
    <p><span style={{fontWeight:'bold', fontSize:'20px', color:'#fc8d04'}}>Comprehensive Drone Education Platform </span>providing in-depth content on drone design, operation, and applications.</p>
    <p>Industry Partnerships and Expertise Leverage our trusted reputation as a vendor to DRDO and DPSUs for unmatched expertise and reliability.</p>
    <p>500+ Hours of Premium Content
Give students access to video tutorials, case studies, and simulations that make drone technology accessible and exciting. 
</p>
<p>Indian-Made,<span style={{fontWeight:'bold', fontSize:'20px', color:'#fc8d04'}}>Cyber-Secure Drones with hands-on experience using easy-to-operate drones</span>  that meet global quality standards.</p>
<p>End-to-end support to ensure seamless integration of drone education into your curriculum</p>

  </div>
</div>
      <div className="accordion-section">
        <h2 className="faq-heading"> LMS Frequently Asked Questions</h2>
        <br />
        <br />
        {accordionData.map((item, index) => (
          <div key={index} className="home-accordion">
            <button
              className="accordion-header"
              onClick={() => toggleAccordion(index)}
            >
              {item.title}
              <span
                className="accordion-icon"
                style={{ color: "black", fontWeight: "bold", fontSize: "20px" }}
              >
                {activeIndex === index ? "-" : "+"}
              </span>
            </button>
            {activeIndex === index && (
              <Fade direction="left" duration={500} triggerOnce>
                <div className="accordion-content">{item.content}</div>
              </Fade>
            )}
          </div>
        ))}
      </div>
      <Footer/>
    </div>
  );
}

export default Homepage;
