import React, { useRef, useEffect } from "react";
import "./BatchForm.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import pdfFile from "../Download/Book.xlsx"; // Adjust the path to your PDF file
import Swal from "sweetalert2";
import Footer from "../Footer/Footer";
import { API_URL } from "../../global";

const BatchForm = () => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => currentYear - i);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const {id} = useParams();

  const validationSchema = Yup.object().shape({
    batchName: Yup.string().required("Batch Name is required"),
    session: Yup.string().required("Session is required"),
    studyingYear: Yup.string().required("Studying Year is required"),
    department: Yup.string().required("Department is required"),
    file: Yup.mixed().required("File upload is required"),
    startYear: Yup.number()
      .required("Year is required")
      .integer("Start Year must be an integer"),
    endYear: Yup.number()
      .required("End Year is required")
      .integer("End Year must be an integer")
      .test(
        "is-greater",
        "End Year must be greater than Start Year",
        function (value) {
          const { startYear } = this.parent;
          return startYear && value > startYear;
        }
      ),
  });

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "excel.xlsx";
    link.click();
  };

  //*  behavior: "smooth", */
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    
      <div style={{marginTop:"50px"}}>
      <Formik
  initialValues={{
    college_id: id,
    batchName: "",
    session: "",
    studyingYear: "",
    department: "",
    file: null,
    startYear: "",
    endYear: ""
   
  }}
  validationSchema={validationSchema}
  onSubmit={async (values) => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // Show SweetAlert2 loading state
    Swal.fire({
      title: "Uploading...",
      text: "Please wait while we upload your data.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });

    try {
      const response = await fetch(`${API_URL}/batch`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.message || "Something went wrong! Please try again.",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Uploaded Successfully!",
          text: "Your batch has been successfully submitted.",
        });

        navigate(-1); // Navigate back on success
      }
    } catch (error) {
      console.error("Error submitting form:", error);

      Swal.fire({
        icon: "error",
        title: "Submission Failed!",
        text: "Unable to submit your batch. Please try again later.",
      });
    }
  }}
>
 {({ setFieldValue, errors, touched }) => (
            <Form>
              <div className="batch-form-body">
                <div className="batch-form-container">
                  <h2 className="editbatchTag text-center">Batch Form</h2>
                  <div className="batch-form-row">
                    <div className="batch-form-input-data">
                      <Field
                        type="text"
                        name="batchName"
                        className={
                          touched.batchName && errors.batchName
                            ? "input-error"
                            : ""
                        }
                      />
                      <label>Batch Name</label>
                      <ErrorMessage
                        name="batchName"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="batch-form-input-data">
                      <Field
                        as="select"
                        name="session"
                        className={
                          touched.session && errors.session ? "input-error" : ""
                        }
                      >
                        <option value="">Select Session</option>
                        <option value="session1">Morning</option>
                        <option value="session2">Afternoon</option>
                        <option value="session3">Evening</option>
                      </Field>
                      <label>Session</label>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="batch-form-row">
                
                
                    <div className="batch-form-input-data">
                      <label>Batch Year</label>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {/* <span
                          style={{ marginRight: "10px", color: "rgb(245, 164, 1)" }}
                        >
                          From
                        </span> */}

                        <Field as="select" name="startYear">
                          <option value="" disabled className="select-placeholder">
                            Select Start Year
                          </option>
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </Field>
                        <span style={{ margin: "0 10px", fontWeight: "bold" }}>
                          {" "}
                        {" "}
                        </span>
                        {/* <span
                          style={{ marginRight: "10px", color: "rgb(245, 164, 1)" }}
                        >
                          To
                        </span> */}
                        <Field as="select" name="endYear">
                          <option value="" disabled>
                            Select End Year
                          </option>
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <ErrorMessage
                        name="startYear"
                        component="div"
                        className="error"
                      />
                      <ErrorMessage
                        name="endYear"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="batch-form-row">
                    <div className="batch-form-input-data" >
                      <Field
                        as="select"
                        name="department"
                        className={
                          touched.department && errors.department
                            ? "input-error"
                            : ""
                        }
                      >
                        <option value="">Select Department</option>
                        <option value="Aerospace">Aerospace</option>
                        <option value="Aeronautical">Aeronautical</option>
                        <option value="CSE">CSE</option>
                        <option value="EEE">EEE</option>
                        <option value="ECE">ECE</option>
                        <option value="IT">IT</option>
                        <option value="Mech">Mech</option>
                        <option value="B.Tech IT">B.Tech IT</option>
                        <option value="B.Tech CSE">B.Tech CSE</option>
                      </Field>
                      <label >Department</label>
                      <ErrorMessage
                        name="department"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="batch-form-input-data">
                      <Field 
                        as="select"
                        name="studyingYear"
                        className={
                          touched.studyingYear && errors.studyingYear
                            ? "input-error"
                            : ""
                        }
                      >
                        <option value="" disabled>
                          Select Year
                        </option>
                        <option value="1st Year">1st Year</option>
                        <option value="2nd Year">2nd Year</option>
                        <option value="3rd Year">3rd Year</option>
                        <option value="4th Year">4th Year</option>
                      </Field>

                      <label>Studying Year</label>
                      <ErrorMessage
                        name="studyingYear"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="batch-form-row">
                   
                    <div className="batch-form-input-data">
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          if (file) {
                            const fileExtension = file.name.split(".").pop().toLowerCase();
                            if (fileExtension === "xls" || fileExtension === "xlsx") {
                              setFieldValue("file", file);
                            } else {
                              setFieldValue("file", null);
                              alert(
                                "Please upload only Excel files (with .xls or .xlsx extensions)."
                              );
                              fileInputRef.current.value = "";
                            }
                          }
                        }}
                        accept=".xls, .xlsx"
                        className={touched.file && errors.file ? "input-error" : ""}
                      />
                      <label>Upload File</label>
                      <ErrorMessage
                        name="file"
                        component="div"
                        className="error"
                      />
             
                    </div>
                    <Button 
                    className="btn btn-warning mt-1"
                    // variant="contained"
                    onClick={handleDownload}
                  >
                    Download Excel Template
                  </Button>
                 
                  </div>
               
              <div className="text-center">
              <Button
                    className="batch-form-submit-btn "
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
              </div>
                </div>
            
              </div>
            
            </Form>
          )}
        </Formik>
      </div>
     <Footer/>
    </>
  );
};

export default BatchForm;
